import React from 'react'
import './Reviews.css'
import Reviewimg from '../../Assets/Reviews/avatar.png'

const Reviews = (props) => {
  return (
    <section id="reviews">
      <div className='title_outer'>
          <h2 className='section_title'>Reviews</h2>
      </div>
      <div id="carouselExampleDark" class="carousel carousel-dark slide carousal-width w-100-p">
        <div class="carousel-indicators carsoul-scroll-bars">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <div className="img-box">
              <img src={Reviewimg} alt="" />
            </div>
            <div className="review-text">
              <h5>{props.name1}</h5>
              <p>
                Sleek and user-friendly websites delivered promptly. This website development company combines creativity with technical proficiency, providing a seamless experience from concept to execution.
              </p>
            </div>
            <div className="review-stars">
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="2000">
            <div className="img-box">
              <img src={Reviewimg} alt="" />
            </div>
            <div className="review-text">
              <h5>{props.name2}</h5>
              <p>
                Outstanding results! The website development team not only met but exceeded our design and functionality requirements. Professional, responsive, and highly recommended for anyone seeking top-notch web solutions.
              </p>
            </div>
            <div className="review-stars">
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
              <span class="material-symbols-outlined stars-color full-review-star">star</span>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev prev-review-btn" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="rgba(70,162,159,1)"></path></svg>
        </button>
        <button class="carousel-control-next next-review-btn" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40"><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z" fill="rgba(70,162,159,1)"></path></svg>
        </button>
      </div>
    </section>
  )
}

export default Reviews
