import { React, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../Assets/Img/Modern Creative Technology Logo.png";
import { FaAngleLeft } from "react-icons/fa";

const Header = (props) => {
  const location = useLocation();

  function opensidemenu() {
    const sidemenu = document.getElementById("mobile_header_menu");
    sidemenu.style.transform = "scaleX(1)";
  }
  function sidemenuclose() {
    const sidemenu = document.getElementById("mobile_header_menu");
    sidemenu.style.transform = "scaleX(0)";
  }

  function couponclose() {
    const coupon = document.getElementById("coupon");
    const tag = document.getElementById("ceo-tag");
    const text = document.getElementById("tag-text");
    const arrow = document.querySelector(".left-arrow");
    coupon.classList.toggle("mx-d-145");
    tag.classList.toggle("w-10p");
    text.classList.toggle("d-none");
    arrow.classList.toggle("d-none");
  }

  useEffect(() => {
    const menuItems = document.querySelectorAll(".menu-item");
    const Sections = document.querySelectorAll("section");

    let currectSection = "home";

    window.addEventListener("scroll", () => {
      Sections.forEach((Section) => {
        if (window.scrollY >= Section.offsetTop - 100) {
          currectSection = Section.id;
        }
      });
      menuItems.forEach((menuItem) => {
        if (menuItem.href.includes(currectSection)) {
          document.querySelector(".active").classList.remove("active");
          menuItem.classList.add("active");
          if (location.pathname === "/courses") {
            menuItem.classList.remove("active");
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    const header = document.getElementById("stickyheader");
    const logo = document.querySelector(".logo");

    const handleScroll = () => {
      if (!header || !logo) return; // Check if elements exist

      const handleOffset = window.scrollY >= 150;
      const courseRoute = location.pathname === '/courses';

      // Apply styles based on conditions
      header.style.height = handleOffset || courseRoute ? "60px" : "";
      header.style.backgroundColor = handleOffset ? "var(--background-color)" : "";
      logo.style.height = handleOffset || courseRoute ? "45px" : "";
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Run the function initially to set the correct styles on route change
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]); // The effect will run on route change as well
  
  useEffect(() => {
    const Menu = document.querySelector(".nav-list");

    if (location.pathname.includes("courses")) {
      Menu.style.display = "none";
    } else {
      Menu.style.display = 'flex';
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  return (
    <>
      <div className="header" id="stickyheader">
        <div className="navbar">
          <a href="/" className="logo">
            <img src={logo} alt="Logo" className="logoimg" />
          </a>
          <ul className="nav-list">
            <li>
              <a href="/#home" className="menu-item active">
                Home
              </a>
            </li>
            <li>
              <a href="/#about" className="menu-item">
                About
              </a>
            </li>
            <li>
              <a href="/#reviews" className="menu-item">
                Reviews
              </a>
            </li>
            <li>
              <a href="/#faqs" className="menu-item">
                FAQ's
              </a>
            </li>
            <li>
              <a href="/#partners" className="menu-item">
                Partners
              </a>
            </li>
            <li>
              <a href="/#contact" className="menu-item">
                Contact us
              </a>
            </li>
            <Link to="courses">
              <button className="btn-course">Courses</button>
            </Link>
          </ul>
        </div>

        {/* CEO Name Coupon */}
        <div className="coupon-container" id="coupon">
          <div className="coupon">
            <div className="name-card">
              <h6 className="coupon-name">
                <b>CEO:</b>{" "}
                <span style={{ fontWeight: 400 }}>{props.ceoname}</span>
              </h6>
            </div>
            <div className="ceo-tag" id="ceo-tag" onClick={couponclose}>
              <FaAngleLeft className="left-arrow" />
              <h6 className="ceo-collapse d-none" id="tag-text">
                <span className="f-13">C</span>
                <br />E<br />
                <span className="f-12">O</span>
              </h6>
            </div>
          </div>
        </div>

        <div id="model_open_button" className="menu-box" onClick={opensidemenu}>
          <div className="menu-lines"></div>
          <div className="menu-lines"></div>
          <div className="menu-lines"></div>
        </div>
        <div className="mobile_header_menu" id="mobile_header_menu">
          <div className="cross_btn" onClick={sidemenuclose}>
            {" "}
            &times;{" "}
          </div>
          <ul className="mobile_sidebar_menu">
            <a href="/" onClick={sidemenuclose}>
              <li>Home</li>
            </a>
            <a href="#about" onClick={sidemenuclose}>
              <li>About</li>
            </a>
            <a href="#reviews" onClick={sidemenuclose}>
              <li>Reviews</li>
            </a>
            <a href="#faqs" onClick={sidemenuclose}>
              <li>FAQ's</li>
            </a>
            <a href="#partners" onClick={sidemenuclose}>
              <li>Partners</li>
            </a>
            <a href="#contact" onClick={sidemenuclose}>
              <li>Contact us</li>
            </a>
            <Link to="courses" onClick={sidemenuclose}>
              <button className="btn-course btn-phone-course">Courses</button>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
