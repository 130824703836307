import React from 'react'
import './Footer.css'
import {AiFillFacebook} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="copyright">
            <p>Copyright &copy; Creative Solutions All Rights Reserved</p>
        </div>
    </footer>
  )
}

export default Footer