import React from 'react'
import "./Banner.css"
import Banner1 from "../../Assets/Img/Banner7.jpg"
import Banner2 from "../../Assets/Img/Banner9.jpg"
import Banner3 from "../../Assets/Img/Banner1.jpg"
import Banner4 from "../../Assets/Img/Banner6.jpg"


const Banner = () => {
  return (
    <section id='home'>
      <div id="carouselExample" class="carousel slide banner-carsoul">
        <div class="carousel-inner banner-inner-carsoul d-none-p">
          <div class="carousel-item active">
            <div className="slider_text" id='slide_text_designing'>
              <h2 className='landing-page-text'>Creative Solutions</h2>
              <p> An institute of website designing & development from professional developers.</p>
            </div>
            <img src={Banner1} class="d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <div className="slider_text slide_text_designing">
              <h1>Professional</h1>
              <p>We have assisted numerous businesses in establishing their brands.</p>
            </div>
            <img src={Banner2} class="d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <div className="slider_text slide_text_designing">
              <h1>Satisfaction</h1>
              <p>Our success is in the satistaction of clients to us</p>
            </div>
            <img src={Banner3} class="d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <div className="slider_text slide_text_designing">
              <h1>Institution</h1>
              <p>we offer a wealth of potential for enhancing your coding skills</p>
            </div>
            <img src={Banner4} class="d-block w-100" alt="..."/>
          </div>
        </div>
        <button class="carousel-control-prev prev-banner-btn" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next next-banner-btn" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      {/* Phone */}
      <div className="banner-img-p">
        <img src={Banner1} alt="Creative Solution" />
        <h2 className='landing-page-text'>Creative Solutions</h2>
      </div>

    </section>
  )
}

export default Banner
