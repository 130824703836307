import React from "react";
import "./About.css";
import About_Image from "../../Assets/Img/About_image.jpg";
import Academic from "../../Assets/Img/acedamic.jpg";
import DevOps from '../../Assets/Img/devops.png'

const About = () => {
  return (
    <>
      <section className="about-section" id="about">
        <div className="title_outer">
          <h2 className="section_title">About us </h2>
        </div>
        <div className="about_content_section">
          <div className="about-content">
            <h3> We are making difference</h3>
            <p>
              Creative Institute is a place where you can learn a lot and let
              your creative ideas flow. Our mission is to help you become a
              skilled web developer of the future. Here, you'll embark on a
              fascinating journey where you combine your creativity with coding
              to understand how complex websites work.
            </p>
            <p>
              Our courses are made for beginners, so even if you're new to web
              development, you'll feel right at home. We'll teach you the
              essential skills for web design and open doors to a world filled
              with endless opportunities in the ever-changing digital field.
            </p>
            <p>
              At Creative Institute, you'll discover a dynamic mix of creativity
              and technology. You'll learn how to create websites that not only
              look great but also work smoothly. Our goal is simple: we want to
              empower you to become a digital expert. We'll provide you with the
              tools and knowledge needed to build engaging and functional
              websites in a constantly evolving digital world. With Creative
              Institute, you're not just learning web development; you're
              starting an exciting journey into the future of digital creation.
            </p>
          </div>
          <div className="about-image">
            <img src={About_Image} alt="about" />
          </div>

          {/* Phone View */}
          <div className="about-content_phone">
            <p>
              Creative Institute is a place where you can learn a lot and let
              your creative ideas flow. Our mission is to help you become a
              skilled web developer of the future. Here, you'll embark on a
              fascinating journey where you combine your creativity with coding
              to understand how complex websites work.
            </p>
            <p>
              Our courses are made for beginners, so even if you're new to web
              development, you'll feel right at home. We'll teach you the
              essential skills for web design and open doors to a world filled
              with endless opportunities in the ever-changing digital field.
            </p>
            <p>
              At Creative Institute, you'll discover a dynamic mix of creativity
              and technology. You'll learn how to create websites that not only
              look great but also work smoothly. Our goal is simple: we want to
              empower you to become a digital expert. We'll provide you with the
              tools and knowledge needed to build engaging and functional
              websites in a constantly evolving digital world. With Creative
              Institute, you're not just learning web development; you're
              starting an exciting journey into the future of digital creation.
            </p>
          </div>
        </div>

        <div className="about_content_section py-40">
          <div className="about-image">
            <img src={Academic} alt="Academy" />
          </div>
          <div className="about-content">
            <h3>Development</h3>
            <p>
              At Creative Solution, we take pride in our team of expert
              programmers, specializing in a diverse range of technologies. This
              empowers us to provide comprehensive solutions tailored to your
              unique project requirements.
            </p>
            <p>
              Our commitment to excellence goes beyond the ordinary, as we
              seamlessly integrate creativity with technical expertise. Whether
              you're envisioning a cutting-edge mobile application, a dynamic
              web platform, or a robust API, Creative Solution is your go-to
              partner for turning ideas into reality.
            </p>
            <p>
              Experience the synergy of skill, innovation, and reliability with
              Creative Solution—a company poised to shape the digital landscape
              and elevate your projects to new heights. Welcome to a world where
              your success is our primary focus, and solutions are crafted with
              a passion for excellence.
            </p>
          </div>
          {/* Phone View */}
          <div className="about-content_phone">
            <p>
              At Creative Solution, we take pride in our team of expert
              programmers, specializing in a diverse range of technologies. This
              empowers us to provide comprehensive solutions tailored to your
              unique project requirements.
            </p>
            <p>
              Our commitment to excellence goes beyond the ordinary, as we
              seamlessly integrate creativity with technical expertise. Whether
              you're envisioning a cutting-edge mobile application, a dynamic
              web platform, or a robust API, Creative Solution is your go-to
              partner for turning ideas into reality.
            </p>
            <p>
              Experience the synergy of skill, innovation, and reliability with
              Creative Solution—a company poised to shape the digital landscape
              and elevate your projects to new heights. Welcome to a world where
              your success is our primary focus, and solutions are crafted with
              a passion for excellence.
            </p>
          </div>
        </div>
        <div className="about_content_section">
          <div className="about-content">
            <h3>DevOps at Creative Solutions</h3>
            <p>
              At Creative Solutions, we specialize in providing comprehensive DevOps courses and services. Our DevOps course at Creative Institute offers a thorough introduction to DevOps principles and practices. You'll learn core concepts like CI/CD, Infrastructure as Code, and effective monitoring and logging. The course emphasizes collaboration and communication between development and operations teams.
            </p>
            <p>
              At Creative Institute, we offer hands-on learning with real-world projects, expert instructors, and state-of-the-art facilities. Our career support includes resume building and interview preparation. Through practical training and real-world scenarios, we ensure our students gain a deep understanding of DevOps. Join us to acquire the technical skills needed to creatively solve complex problems, driving business growth and innovation.
            </p>
          </div>
          <div className="about-image">
            <img src={DevOps} alt="about" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
