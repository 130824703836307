import React, { useState, useEffect } from "react";
import "./Courses.css";
import WebDevelopment from "../../Icons/Website-Developement.png";
import MobileApp from "../../Icons/Mobile App.png";
import PcApp from "../../Icons/Window App Development.png";
import SEO from "../../Icons/SEO.png";
import Wordpress from "../../Icons/Wordpress.png";
import AmazonE from "../../Icons/Amazon.png";
import Shopify from "../../Icons/shopify.png";
import WooCommerce from "../../Icons/woo-commerce.png";
import CoursesSkeleton from "../CoursesSkeleton";
import DevOps from "../../Icons/devops-svgrepo-com (1).png";

const Courses = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <section id="courses" className="courses">
      <div>
        <h5 className="course-contact-phone">
          <strong>Note: </strong>
          &nbsp;For more details, contact our CEO at <span>+92 313 5940723</span> 
        </h5>
      </div>
      <hr className="divider my-10" />
      {loading ? (
        <CoursesSkeleton />
      ) : (
        // Actual content
        <>
          {/* Row #1 */}
          <div className="row">
            <div className="course-container">
              <img src={DevOps} alt="WordPress" className="course-img" />
              <h4 className="course-name">DevOps</h4>
            </div>

            <div className="course-container">
              <img
                src={WebDevelopment}
                alt="Website Development"
                className="course-img"
              />
              <h4 className="course-name">Website Development</h4>
            </div>

            <div className="course-container">
              <img
                src={PcApp}
                alt="Windows Application Development"
                className="course-img"
              />
              <h4 className="course-name">Windows Application Development</h4>
            </div>

            <div className="course-container">
              <img
                src={MobileApp}
                alt="Mobile Application Development"
                className="course-img"
              />
              <h4 className="course-name">Mobile Application Development</h4>
            </div>
          </div>

          {/* Row #2 */}
          <div className="row">
            <div className="course-container">
              <img src={SEO} alt="SEO" className="course-img" />
              <h4 className="course-name">SEO</h4>
            </div>

            <div className="course-container">
              <img src={Wordpress} alt="WordPress" className="course-img" />
              <h4 className="course-name">WordPress</h4>
            </div>

            <div className="course-container">
              <img
                src={AmazonE}
                alt="Amazon FBA Hole Sale"
                className="course-img"
              />
              <h4 className="course-name">Amazon</h4>
            </div>

            <div className="course-container">
              <img
                src={WooCommerce}
                alt="Woo-Commerce"
                className="course-img"
              />
              <h4 className="course-name">Woo-Commerce</h4>
            </div>
          </div>
          {/* Row #2 */}
          <div className="row">
            <div className="course-container">
              <img src={Shopify} alt="Shopify" className="course-img" />
              <h4 className="course-name">Shopify</h4>
            </div>

            <div className="course-container">
              <img
                src={AmazonE}
                alt="Amazon FBA Hole Sale"
                className="course-img"
              />
              <h4 className="course-name">Amazon FBA Hole Sale</h4>
            </div>
          </div>
        </>
      )}

      <hr className="divider" />
    </section>
  );
};

export default Courses;
