import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './style/Skeleton.css';
import Header from './Components/Header/Header';
import Banner from './Components/Banner/Banner';
import About from './Components/About/About';
import FAQ from './Components/FAQ/FAQ';
import Reviews from './Components/Reviews/Reviews';
import Courses from './Components/Courses/Courses';
import Partners from './Components/Partners/Partners';
import Footer from './Components/Footer/Footer';
import CEO from './Components/CEO/CEO';

// Media
import CEOImg from '../src/Assets/CEO/Abdullah Faisal.png'

function App() {
  return (
    <>
      <Router>
        <Header ceoname="Abdullah Faisal" />
        <Routes>
          <Route exact path="/" element={
            <React.Fragment>
              <Banner />
              <About />
              <Reviews name1="Client #1" name2="Client #2" />
              <FAQ />
              <Partners />
              <CEO
                CEOName="Abdullah Faisal"
                Phone="+92 313 594-0723 | +92 326 547-8348"
                ContactEmail="contact@creativesolutions.live"
                SupportEmail="support@creativesolutions.live"
                Image={CEOImg}
              />
              <Footer />
            </React.Fragment>
          } />
          <Route path="/courses" element={<Courses />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
