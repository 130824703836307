import React from 'react'
import './Partners.css'
import Contigo from '../../Assets/Partners/Contigo.png'
import Federal from '../../Assets/Partners/Ferderal.jpeg'
import Khastech from '../../Assets/Partners/Khastech.png'

const Partners = () => {
  return (
    <section className='partners-section' id='partners'>
        <hr className='divider w-50' />
        <div className="partners-container w-95-p">
            <img src={Khastech} alt="" className='partner-img' />
            <img src={Federal} alt="" className='partner-img img-circle' />
            <img src={Contigo} alt="" className='partner-img' />
        </div>            
        <hr className='divider w-50' />
    </section>
  )
}

export default Partners
