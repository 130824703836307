import React from 'react'
import './CEO.css'
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
const CEO = (props) => {
  return (
    <section className='owner-section' id='contact'>
      <div className="owner-detail">
        <div className="contact-header">
          <div className="ceo-img">
            <img src={props.Image} alt="" />
          </div>
          <div className="contact-detail">
            <h2>{props.CEOName} <span className='ceo-tag'>(CEO)</span></h2>
            {/* <p>Mobile & Web App Developer</p> */}
            <div className="contact-info">
              <div className="phone">
                <FaPhoneAlt /> &nbsp;
                <span>{props.Phone}</span>
              </div>
              <div className="email">
                <MdOutlineMail /> &nbsp;
                <span>{props.ContactEmail}</span>
              </div>
              <div className="email">
                <MdOutlineMail /> &nbsp;
                <span>{props.SupportEmail}</span>
              </div>
            </div>

            <div className="social-container">
              {/* <a href="">
                <div className="facebook">
                  <FaFacebookF className='social-icon mx-15' />
                </div>
              </a> */}
              <a
                href="https://www.linkedin.com/in/abdullah-faisal-12122b234/"
                className="linkedin"
              >
                <FaLinkedinIn className='social-icon' />
              </a>
            </div>

            <div className="desc-container">
              <p>
                Abdullah Faisal is a seasoned professional with a diverse skill set in DevOps practices, full stack web development, mobile, Windows desktop application development  cloud computing, and API creation in all Languages. His dedication to staying at the forefront of technology trends ensures the delivery of innovative solutions.
              </p>
              <p>
                As the CEO of Creative Solutions, Abdullah leads the best team for IT services. Additionally, he runs Creative Solutions Academy, one of the top academies in Pakistan. Whether it's crafting user-friendly websites, developing cross-platform mobile apps, optimizing operations with cloud computing, or streamlining workflows with DevOps methodologies, Abdullah brings expertise and passion to every project. Let's turn your tech dreams into reality.
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </section>
  )
}

export default CEO
